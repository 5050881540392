@import 'variables';

.medis-toggle-group-wrapper {
  border-top: 2px solid $color-azure;
  text-align: right;

  mat-button-toggle-group {
    &:first-child {
      border-bottom-left-radius: 2px;
    }

    &:last-child {
      border-bottom-right-radius: 2px;
    }

    mat-button-toggle.mat-button-toggle {
      border: 2px solid $color-azure;
      border-top: none;
      background-color: $color-white;

      > button.mat-button-toggle-button > .mat-button-toggle-label-content {
        color: $color-azure;
        font-weight: 500;
      }

      &-checked {
        background-color: $color-azure;

        > button.mat-button-toggle-button > .mat-button-toggle-label-content {
          color: $color-white;
          font-weight: 800;
        }
      }
    }
  }
}

mat-button-toggle-group.table-header-3-even-toggles {
  display: flex !important;
  height: 34px;
  margin-bottom: 5px;

  .mat-button-toggle {
    margin-top: -10px;
    font-weight: 400;
  }

  > * {
    flex: 0 0 auto;
    width: 33.33%;
  }

  &.icon-toggles .mat-button-toggle-label-content {
    padding: 0;
  }
}
