@use '@angular/material' as mat;

$medis-app-primary: mat.define-palette(mat.$blue-palette, 800, 500, 900);
$medis-app-accent: mat.define-palette(mat.$teal-palette, 800, 600, 900);

// The warn palette is optional (defaults to red).
$medis-app-warn: mat.define-palette(mat.$red-palette, 800, 600, 900);

$medis-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $medis-app-primary,
      accent: $medis-app-accent,
      warn: $medis-app-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core();
@include mat.all-component-bases($medis-app-theme);
@include mat.all-component-typographies($medis-app-theme);
@include mat.all-component-densities($medis-app-theme);
@include mat.all-component-themes($medis-app-theme);

@import 'autocomplete';
@import 'button-toggle-group';
@import 'button';
@import 'card';
@import 'checkbox';
@import 'chip';
@import 'dialog';
@import 'error';
@import 'form-field';
@import 'menu';
@import 'radio-button';
@import 'select';
@import 'slide-toggle';
@import 'snackbar';
@import 'tab-group';
@import 'table';
@import 'tooltip';

// Import medis core library
@import '@mi-tool/scss/core';
@import 'flatpickr/dist/flatpickr.css';
