.mat-mdc-autocomplete-panel {
  mat-option.mat-mdc-option {
    min-height: unset;
    justify-content: unset;

    .mdc-list-item__primary-text {
      margin-right: 0;
      width: 100%;
      letter-spacing: normal;
    }

    &.mdc-list-item--disabled {
      pointer-events: all;

      .mdc-list-item__primary-text {
        opacity: 1;
      }
    }
  }
}
