@import 'variables';

.nav {
  .nav-subheader {
    display: flex;
    align-items: center;
    height: 48px;
    font-weight: 600;
    padding-left: 24px;
    margin-top: 8px;
    font-size: 12px;
    white-space: nowrap;
  }

  .nav-group {
    display: block;

    > .group-title {
      position: relative;
      display: flex;
      align-items: center;
      height: 35px;
      font-weight: 600;
      padding-left: 24px;
      font-size: 12px;
      white-space: nowrap;
      color: black !important;
    }
  }

  .nav-item {
    .nav-link {
      text-decoration: none !important;
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0 24px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      user-select: none;
      color: currentColor;

      > .nav-link-title {
        flex: 1 1 auto;
        white-space: nowrap;
        color: black;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      .mat-ripple-element {
        background-color: rgba(0, 0, 0, 0.04);
      }

      &.active {
        .nav-link-icon {
          opacity: 1;
          color: black;
        }
      }

      .nav-link-icon {
        margin-right: 16px;
        color: black;
      }

      .nav-link-icon,
      .collapsable-arrow {
        font-size: 17px;
        width: 17px;
        height: 17px;
        min-width: 17px;
        min-height: 17px;
        line-height: 17px;
        color: #969db1;
      }
    }

    &.nav-collapsable {
      display: block;

      > .children {
        > .nav-item {
          > .nav-link,
          &.nav-group > .group-title,
          &.nav-group > .group-items > .nav-item > .nav-link {
            padding-left: 56px;
          }

          > .children {
            > .nav-item {
              > .nav-link,
              &.nav-group > .group-title,
              &.nav-group > .group-items > .nav-item > .nav-link {
                padding-left: 72px;
              }
            }
          }
        }
      }
    }
  }

  > .nav-item {
    &.nav-collapsable {
      background: transparent;
      transition: background 200ms ease-in-out;

      &.open {
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }

  > .nav-group {
    > .group-items {
      > .nav-collapsable {
        background: transparent;
        transition: background 200ms ease-in-out;

        &.open {
          background: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }

  &.vertical {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;

    .nav-group {
      .group-title {
        .hint-text {
          color: #a4adb2;
          font-size: 14px;
        }

        .nav-link-icon {
          margin-right: 16px;
          color: $color-sidebar-blue;
          font-size: 17px;
          width: 17px;
          height: 17px;
          min-width: 17px;
          min-height: 17px;
          line-height: 17px;
          opacity: 1;
        }
      }
    }
  }

  // Material 2 style
  &.material2,
  .material2 & {
    .nav-subheader {
      height: 40px;
    }

    .nav-group {
      > .group-title {
        line-height: 1.36;
      }
    }
    .nav-item {
      .nav-link {
        height: 35px;
        padding: 0 12px 0 24px;

        &.active.accent {
          border-radius: 20px 0px 0px 20px;
          background: #ffe587;
          font-weight: bold;
        }
      }
    }
  }
}
