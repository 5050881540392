@import 'variables';

@mixin disable-colors($text-color, $bg-color) {
  background-color: $bg-color;

  span.mdc-button__label {
    color: $text-color;

    i.fa,
    i.fas,
    i.far {
      color: $text-color;
    }
  }
}

@mixin medis-button($state-name, $color, $label-color, $background-color) {
  button.mat-mdc-button.medis-button-#{$state-name} {
    border-radius: 20px;
    border: 2px solid $color;
    background-color: #{$background-color};

    &.compact {
      height: 30px;
    }

    span.mdc-button__label {
      font-size: 14px;
      font-weight: 800;
      color: $label-color;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      i.fa,
      i.fas,
      i.far {
        padding-right: 10px;
        font-size: 16px;
        font-weight: 900;
        color: $label-color;
      }
    }

    &:disabled {
      border-color: $color-lighter-grey;
      cursor: not-allowed;
      pointer-events: all;

      &:not(.solid-disable) {
        @include disable-colors($color-lighter-grey, $color-white);
      }

      &.solid-disable {
        @include disable-colors($color-white, $color-lighter-grey);
      }
    }
  }
}

@include medis-button('basic', $color-azure, $color-azure, 'transparent');
@include medis-button('reject', $color-coral, $color-coral, 'transparent');
@include medis-button('approve', $color-jade, $color-jade, 'transparent');
@include medis-button('main', $color-azure, $color-white, $color-azure);
@include medis-button('white', $color-white, $color-white, 'transparent');
@include medis-button('dark', $color-charcoal-grey, $color-charcoal-grey, 'transparent');
@include medis-button('normal', 'white', $color-charcoal-grey, 'transparent');

button.mat-mdc-icon-button.medis-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.no-border) {
    border: 2px solid #e6e8ef;
  }

  &.no-border {
    width: 24px;
    height: 24px;
    line-height: 24px;

    > .mat-mdc-button-touch-target {
      width: 24px;
      height: 24px;
    }
  }
}

button.mdc-button {
  padding: 0 16px;

  --mdc-text-button-container-height: 40px;
  --mdc-text-button-label-text-tracking: normal;
}

button.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
}
