$color-coral: #e36262;
$color-pale-coral: #fcf7f4;
$color-grass: #59ab1f;
$color-jade: #1fab69;
$color-purple: #aa62e3;
$color-sidebar-blue: #00b4ed;
$color-azure: #18abff;
$color-cerulean: #007dc3;
$color-orange-red: #ff4500;
$color-pale-yellow: #ffffef;
$color-orange-yellow: #ffa800;
$color-charcoal-grey: #363b3d;
$color-bluish-grey: #818b91;
$color-light-grey: #80898e;
$color-cool-grey: #a4adb2;
$color-dark-grey: #454545;
$color-quill-grey: #bfc5c7;
$color-pale-grey: #f7f8fd;
$color-lighter-grey: #b6bdc2;
$color-peacock-blue: #006096;
$color-cloudy-blue: #bdc8cd;
$color-light-sky-blue: #dde3e5;
$color-ice-blue: #ebeff1;
$color-alice-blue: #f3f7f9;
$color-white: #fff;
$color-almost-white: #fcfcfc;
$color-black: #000;
$color-mostly-black: #292b2c;
$color-gunmetal: #485156;
$color-modal: #d8d8d8;

// Redesign - new styles
$color-primary: #18abff;
$color-primary-text: #0083d7;
$color-secondary: #aa62e3;
$color-secondary-text: #8c44c5;
$color-tertiary: #ffa800;
$color-tertiary-text: #f59e00;
$color-attention: #ff6348;
$color-attention-text: #d73b20;
$color-disabled: #b6bdc2;
$color-disabled-text: #8e959a;
$color-success: #63b529;
// ------------------ //
