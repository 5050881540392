@import 'variables';

.loading-modal .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none;
}

mat-dialog-container {
  .mat-mdc-dialog-surface {
    padding: 24px;
    overflow-x: hidden;

    .mat-mdc-dialog-title.mdc-dialog__title {
      padding: 0 0 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::before {
        content: unset;
      }
    }

    mat-dialog-content.mdc-dialog__content,
    .mat-mdc-dialog-content.mdc-dialog__content {
      margin: 0 -24px;
      padding: 5px 24px;
    }

    :not(:has(.mat-mdc-dialog-actions)) .mat-mdc-dialog-content {
      max-height: 80vh;
    }

    mat-dialog-actions.mat-mdc-dialog-actions {
      margin-bottom: -24px;
      justify-content: space-evenly;
      gap: 10px;

      > .mat-mdc-button-base + .mat-mdc-button-base {
        margin-left: 0;
      }

      @media only screen and (max-width: 800px) {
        &.responsive-actions {
          flex-direction: column;
          align-items: stretch;
        }
      }
    }
  }

  --mdc-dialog-subhead-color: $color-charcoal-grey;
  --mdc-dialog-subhead-font: Manrope, sans-serif;
  --mdc-dialog-subhead-line-height: 18px;
  --mdc-dialog-subhead-size: 18px;
  --mdc-dialog-subhead-weight: 700;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-color: $color-charcoal-grey;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-tracking: normal;
}
