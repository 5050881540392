@import 'variables';

mat-radio-button.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-icon-color: #{$color-azure};
  --mdc-radio-selected-hover-icon-color: #{$color-azure};
  --mdc-radio-selected-pressed-icon-color: #{$color-azure};
  --mdc-radio-selected-focus-icon-color: #{$color-azure};
  --mdc-radio-unselected-icon-color: #{$color-azure};
  --mdc-radio-unselected-hover-icon-color: #{$color-azure};
  --mdc-radio-unselected-pressed-icon-color: #{$color-azure};
  --mdc-radio-state-layer-size: 25px;
  --mdc-form-field-label-text-tracking: normal;
}
