@import 'variables';

@mixin state-styles($state, $component, $color) {
  $sub-states: ('', '-hover', '-focus', '-pressed');
  @each $sub-state in $sub-states {
    --mdc-switch-#{$state}#{$sub-state}-#{$component}-color: #{$color};
  }
}

mat-slide-toggle .mdc-switch:disabled {
  cursor: not-allowed;
}

html mat-slide-toggle.mat-mdc-slide-toggle.mat-accent {
  @include state-styles('selected', 'icon', $color-azure);
  @include state-styles('selected', 'handle', $color-azure);
  @include state-styles('selected', 'track', $color-azure);
  @include state-styles('unselected', 'icon', #fafafa);
  @include state-styles('unselected', 'handle', #fafafa);
  @include state-styles('unselected', 'track', rgba(0, 0, 0, 0.38));
  --mdc-form-field-label-text-tracking: normal;
  --mdc-switch-disabled-selected-handle-color: #{$color-lighter-grey};
  --mdc-switch-disabled-selected-icon-color: #{$color-lighter-grey};
  --mdc-switch-disabled-selected-track-color: #{$color-lighter-grey};
  --mdc-switch-disabled-selected-icon-opacity: 1;
  --mdc-switch-disabled-unselected-handle-color: #{$color-lighter-grey};
  --mdc-switch-disabled-unselected-icon-color: #{$color-lighter-grey};
  --mdc-switch-disabled-unselected-track-color: #{$color-lighter-grey};
  --mdc-switch-disabled-unselected-icon-opacity: 1;
  --mdc-switch-disabled-handle-opacity: 1;
  --mdc-switch-disabled-track-opacity: 0.5;
}
